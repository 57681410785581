@function css-function($function, $values...) {
  @return $function + unquote('(') + $values + unquote(')');
}

@function css-min( $values... ) {
  @return css-function( min, $values );
}

.notificationWrapper {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 20px;
  padding: 10px;
  background-color: #e01b44;
  font-family: var(--mol-default-font-family);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  z-index: 9999;

  :global(.mol-desktop) & {
    width: 964px;
    width: css-min(964px, 100vw);
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.notificationClose {
  position: relative;
  width: 20px;
  height: 20px;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  &::before,
  &::after {
    position: absolute;
    content: ' ';
    right: 8px;
    height: 15px;
    width: 1px;
    background-color: #fff;
    cursor: pointer;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.notificationBody {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: start;

  .notificationBanner {
    background-color: #fff;
    padding: 3px 3px 2px;
    margin-bottom: 10px;

    .notificationBreakingNews {
      font-size: 16px;
      color: #e01c44;
      vertical-align: middle;

      .notificationSecondWord {
        color: #004db3;
      }
    }
  }

  .notificationTitle {
    font-size: 16px;
    line-height: 1.25;
    color: #fff;

    /* Clamp text after 2 lines */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
