/* stylelint-disable custom-property-pattern */
@import './constants/channelColors.vars.scss';

:global {
  /**
  * Basic (typography) classes
  *
  * KEYS
  * o = on
  * w = white
  * b = black
  * grN = grey color
  * x = transparent (not set, inherits its value)
  * cc = channnel color
  * lcc = light channel color
  * link = link color
  * linkro = rollover
  *
  * i.e.
  * .sport .ccox - sport color text on transparent background
  *
  */

  @function typography-selectors($class) {
    @return '.#{$class}, .link-#{$class} a, .linkro-#{$class} a:hover ';
  }

  @each $channel, $_ in $channelColors {
    .tlc-#{$channel}-ccox,
    body .#{$channel}-ccox {
      color: var(--channel-color);
    }

    .tlc-#{$channel},
    body .#{$channel} {
      #{typography-selectors('wocc')} {
        color: var(--blackwhite-color-w);
        background-color: var(--channel-color);
      }

      #{typography-selectors('bocc')} {
        color: var(--blackwhite-color-b);
        background-color: var(--channel-color);
      }

      #{typography-selectors('xocc')} {
        background-color: var(--channel-color);
      }

      #{typography-selectors('xolcc')} {
        background-color: var(--channel-color-light);
      }

      #{typography-selectors('lccox')} {
        color: var(--channel-color-light);
      }

      #{typography-selectors('ccow')} {
        color: var(--channel-color);
        background-color: var(--blackwhite-color-w);
      }

      #{typography-selectors('ccob')} {
        color: var(--channel-color);
        background-color: var(--blackwhite-color-b);
      }

      #{typography-selectors('ccox')} {
        color: var(--channel-color);
      }

      #{typography-selectors('ccolcc')} {
        color: var(--channel-color);
        background-color: var(--channel-color-light);
      }

      @each $greyName, $_ in $greyColors {
        #{typography-selectors('cco#{$greyName}')} {
          color: var(--channel-color);
          background-color: var(--grey-color-#{ $greyName });
        }
      }
    }
  }

  @each $blackWhiteName, $_ in $blackWhiteColors {
    #{typography-selectors('#{$blackWhiteName}ox')} {
      color: var(--blackwhite-color-#{ $blackWhiteName });
    }

    #{typography-selectors('xo#{$blackWhiteName}')} {
      background-color: var(--blackwhite-color-#{ $blackWhiteName });
    }

    @each $greyName, $_ in $greyColors {
      #{typography-selectors('#{$blackWhiteName}o#{$greyName}')} {
        color: var(--blackwhite-color-#{ $blackWhiteName });
        background-color: var(--grey-color-#{ $greyName });
      }
    }
  }

  #{typography-selectors('bow')} {
    color: var(--blackwhite-color-b);
    background-color: var(--blackwhite-color-w);
  }

  #{typography-selectors('wob')} {
    color: var(--blackwhite-color-w);
    background-color: var(--blackwhite-color-b);
  }

  @each $greyName, $_ in $greyColors {
    #{typography-selectors('#{$greyName}ox')} {
      color: var(--grey-color-#{ $greyName });
    }

    #{typography-selectors('xo#{$greyName}')} {
      background-color: var(--grey-color-#{ $greyName });
    }
  }

  #{typography-selectors('homeblue')} {
    color: #004db3;
  }

  .linkro-grey a:hover {
    color: #003580;
  }

  .linkro-darkred a:hover {
    color: #c00;
  }

  // Exceptions
  .tlc-debate,
  body .debate,
  .tlc-wellness,
  body .wellness,
  .tlc-podcasts,
  body .podcasts {
    #{typography-selectors('wocc')} {
      color: var(--blackwhite-color-b);
    }

    #{typography-selectors('ccow')} {
      color: var(--blackwhite-color-b);
    }

    #{typography-selectors('ccox')} {
      color: var(--blackwhite-color-b);
    }

    .puff .linkro-wocc a:hover {
      color: var(--blackwhite-color-w);
    }
  }
}
