@mixin icon($icon) {
  display: inline-block;
  vertical-align: bottom;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-image: url('./assets/#{$icon}.svg');
  background-repeat: no-repeat;
}


.mol-mobile {
  .heading-tag-switch {
    .social.bottom {
      border-top: 1px solid silver;
      border-bottom: 1px solid silver;
      padding: 6px 0 12px;

      .social-headings {
        padding: 0 8px;

        h3,
        h1 {
          display: inline;
          font-family: var(--mol-default-font-family);
          font-size: 16px;
          font-weight: bold;
          line-height: 1.13;

          &::after {
            content: " ";
          }
        }
      }

      > ul {
        border-top: none;
      }
    }

    > h2:first-of-type {
      font-family: var(--mol-default-font-family);
      margin: 10px 0 16px;
      font-weight: 500;
      font-size: 23px;
      line-height: 27px;
    }
  }

  .h1-top-article {
    > h1:first-of-type {
      text-indent: -9999px;
      height: 1px;
      margin: 0;
    }
  }
}
