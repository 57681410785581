.new-mobile-article-design {
  .mol-mobile {
    .linkButtonRow.mob-web-redesign {
      gap: 6px;
      margin: 10px 0 0;
      padding-top: 1rem !important;
      border-top: 1px solid #CCC;

      .linkButtonRowItem {
        display: flex;
        height: 30px;
        color: #004db3;
        text-align: center;
        font-family: var(--mol-default-font-family);
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 10px 15px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        border: 1px solid rgba(0, 0, 0, 0);
        background: rgba(0, 0, 0, 0.08);

      }
    }
  }
}

.linkButtonRow {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-flow: row wrap;
  background: #f5f5f5;
  padding: 10px;
  gap: 5px;
  margin-bottom: 24px;

  &.articleTopicsRow {
    padding: 0;
    margin: 16px 0;
    background: none;

    .linkButtonRowItem,
    .linkButtonRowLabel {
      font-size: 16px;
    }
  }

  .linkButtonRowItem,
  .linkButtonRowLabel {
    box-sizing: border-box;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    height: 40px;
    font-weight: bold;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
  }

  .linkButtonRowItem {
    color: #194fad;
    border: 1px solid #ccc;
    background: white;

    &:hover {
      background: #ccc;
    }
  }

  .linkButtonRowLabel {
    color: #fff;
    display: flex;
    background: #194fad;
  }
}

.mol-mobile {
  .linkButtonRow {
    padding: 10px;
    gap: 4px;
    margin: -10px 0 10px;

    &.articleTopicsRow {
      padding: 0;

      .social-links-title {
        display: inline;
        color: var(--bw-greyscale-10191919, #191919);
        font-family: var(--mol-default-font-family);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        width: 100%;
      }
    }

    .linkButtonRowItem,
    .linkButtonRowLabel {
      height: 30px;
      font-size: 14px;
      padding: 0 10px;
    }

    .linkButtonRowItem {
      color: #194fad;
      border: 1px solid #ccc;

      &:hover {
        background: #ccc;
      }
    }
  }

  .breadcrumbs-container {
    margin: 16px 0 32px;
    margin-bottom: 32px;

    .breadcrumbs {
      list-style: none;
      text-align: left;
      width: fit-content;
      margin: 0;

      .breadcrumb-link {
        display: inline;
        color: #004db3;
        font-family: var(--mol-default-font-family);
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin: 0;

        a {
          text-decoration: none;

          &:active,
          &:visited {
            color: inherit;
          }
        }

        & + ::before {
          content: "";
          background: url("./assets/caretRight.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 12px;
          height: 12px;
          display: inline-block;
          opacity: 0.8;
          margin: 0 4px;
        }
      }
    }
  }
}
