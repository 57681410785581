@import './globalCssVariables.scss';

/*
 TODO: Remove all the Graphik "./assets" folder and logic in this file once we have implemented the Inter font-family and removed Graphik from everywhere.
 Related ticket: https://jira.mol.dmgt.net/browse/MOL-28938
*/
$fontFamilies: (
  'graphik': (
    baseUrl: '//i.dailymail.co.uk/i/fonts/graphik-official/graphik-',
    fonts: (
      'regular': (weight: 400, style: normal, inline: true),
      'medium': (weight: 500, style: normal),
      'semibold': (weight: 700, style: normal, inline: true),
      'light': (weight: 300, style: normal),
      'italic': (weight: 400, style: italic),
      'mediumitalic': (weight: 500, style: italic),
      'semibolditalic': (weight: 700, style: italic),
      'lightitalic': (weight: 300, style: italic)
    )
  )
);

@each $familyName, $familyConfig in $fontFamilies {
  $baseFontLocation: map-get($familyConfig, 'baseUrl');

  @each $name, $config in map-get($familyConfig, 'fonts') {
    @font-face {
      font-family: $familyName;

      @if map-get($config, 'inline') {
        src:
          url('./assets/#{$familyName}/#{$name}.woff') format('woff'),
          url('#{$baseFontLocation}#{$name}.ttf') format('truetype');
      } @else {
        src:
          url('#{$baseFontLocation}#{$name}.woff2') format('woff2'),
          url('#{$baseFontLocation}#{$name}.woff') format('woff'),
          url('#{$baseFontLocation}#{$name}.ttf') format('truetype');
      }

      font-weight: map-get($config, 'weight');
      font-style: map-get($config, 'style');
    }
  }
}
