:global {
    @import "./heading-tag-switch-mobile";
    @import "./heading-tag-switch-desktop";
    @import "./rss-feeds-buttons";
    @import "./tiny-floater";
    @import "./slidey";
    @import "./linkButtonRow";
    @import "./articleListRow";
    @import "./social-bottom-mobile.scss";
    @import "./topicPage";
    @import "./molEmbed";
    @import "./placeholder/royalsHeadlines";
    @import "./rating-stars";
    @import "./placeholder/paywall";
}
