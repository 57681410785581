@import './constants/channelColors.vars.scss';

:global {
  // GLOBAL CSS VARIABLES FOR CHANNEL COLORS:
  :root {
    --channel-color: #{ map-get(map-get($channelColors, home), main) };
    --channel-color-light: #{ map-get(map-get($channelColors, home), light) };
    --channel-color-light-background: #{ map-get(map-get($channelColors, home), light-background) };

    @each $channel, $colors in $channelColors {
      /* EACH CHANNEL COLORS: */
      --channel-color-#{ $channel }: #{ map-get($colors, main) };
      --channel-color-#{ $channel }-light: #{ map-get($colors, light) };
      --channel-color-#{ $channel }-light-background: #{ map-get($colors, light-background) };
    }

    @each $colorName, $greyColor in $greyColors {
      --grey-color-#{ $colorName }: #{ $greyColor };
    }

    @each $colorName, $blackWhiteColor in $blackWhiteColors {
      --blackwhite-color-#{ $colorName }: #{ $blackWhiteColor };
    }
  }

  // NON-GLOBAL CHANNEL COLOR CSS VARIABLES:
  @each $channel, $colors in $channelColors {
    [data-channel-color='#{ $channel }'],
    .tlc-#{ $channel }, // borderColors.scss
    .tlc-#{ $channel }-ccox, // typographyColors.scss
    body .#{ $channel }, // borderColors.scss
    body .#{ $channel }-ccox , // typographyColors.scss
    .msa-container.#{ $channel }, // most-shared-articles package
    {
      $mainColor: map-get($colors, main);
      $lightColor: map-get($colors, light);
      $lightBackgroundColor: map-get($colors, light-background);

      /* HEXADECIMAL COLORS: */
      --channel-color: #{ $mainColor };
      --channel-color-light: #{ $lightColor };
      --channel-color-light-background: #{ $lightBackgroundColor };
 
      /* RGB COLORS: */ 
      --channel-color-rgb: #{ red($mainColor) }, #{ green($mainColor) }, #{ blue($mainColor) };
      --channel-color-light-rgb: #{ red($lightColor) }, #{ green($lightColor) }, #{ blue($lightColor) };
      --channel-color-light-background-rgb: #{ red($lightBackgroundColor) }, #{ green($lightBackgroundColor) }, #{ blue($lightBackgroundColor) };
    }
  }
}
